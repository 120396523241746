import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { AppLayout, AppLayoutProps, Spinner } from '@amzn/awsui-components-react-v3';
import { PrivateRoute, PrivateRouteProps } from '@wfo/authorization-components';
import { Notifications, useAppSplitPanelProps, useAppToolsProps } from '@wfo/common-components';

import * as pagePaths from '../constants/page-paths';
import {
  EXCEL_ADD_IN_DOMAIN_RESOURCE,
  VIBE_DOMAIN_RESOURCE_TYPE,
  VIBE_VIEW_OPERATION,
  VibeDomainResources
} from '../constants/rbac-resources';
import { useAppContext } from '../context/appContext';
import { AppConfig } from '../context/authService';
import { isCnRegionEnabledForPath, isRegionSelectorEnabledForPath } from '../utilities/region-selector-enabled-pages';
import AccessDenied from './access-denied/AccessDenied';
import { AuthRegionAccessor } from './auth-region-selector/auth-region-accessor';
import ErrorPage from './error-page/ErrorPage';
import Footer from './footer/Footer';
import Header from './header/Header';
import { RegionAccessor } from './header/region-selector/region-accessor';
import Home from './home/Home';
import Navigation from './navigation/Navigation';
import { StyledHeader } from './header/styles';

const MDMApp = lazy(() => import('@wfo/mdm-components'));
const RtwoApp = lazy(() => import('@wfo/rtwo-components'));
const ForecastingApp = lazy(() => import('@amzn/wfo-forecasting-components'));
const GenAIApp = lazy(() => import('@amzn/wfo-ai-components'));
const PlanningApp = lazy(() => import('@wfo/planning-components'));
const ScheduleManagementApp = lazy(() => import('@wfo/schedule-management-components'));
const ReportsApp = lazy(() => import('@wfo/report-components'));
const ConfigurationsApp = lazy(() => import('@wfo/configurations-components'));
const NotificationCenterApp = lazy(() => import('@wfo/notification-center-components'));
const InputsCentral = lazy(() => import('@amzn/wfo-inputs-central-components'));
const ExcelAddIn = lazy(() => import('@amzn/wfo-vibe-excel-add-in'));

const commonPrivateRouteProps: Pick<
  PrivateRouteProps,
  'resourceType' | 'operation' | 'redirectToOnUnauthorized' | 'redirectToOnError'
> = {
  resourceType: VIBE_DOMAIN_RESOURCE_TYPE,
  operation: VIBE_VIEW_OPERATION,
  redirectToOnUnauthorized: pagePaths.ACCESS_DENIED_PAGE,
  redirectToOnError: pagePaths.ERROR_PAGE
};

interface AuthenticatedAppProps {
  user: string;
  appConfig: AppConfig;
}

const AuthenticatedApp: React.FC<AuthenticatedAppProps> = ({ user, appConfig }) => {
  const history = useHistory();
  const { location } = history;
  const { pathname } = useLocation();
  const { logout } = useAppContext();
  const toolsProps = useAppToolsProps();
  const splitPanelProps = useAppSplitPanelProps();
  const { i18n } = useTranslation();
  const showRegionSelector = isRegionSelectorEnabledForPath(pathname);
  const showCnRegion = isCnRegionEnabledForPath(pathname);
  const regionAccessor = useMemo(
    () => new RegionAccessor(history, new AuthRegionAccessor(appConfig)),
    [appConfig, history]
  );
  // We want the side navigation bar to be open by default
  const [navigationOpen, setNavigationOpen] = useState(true);

  const isNavigationHidden = useMemo(() => {
    const pathName = location.pathname;
    return pagePaths.PATH_LIST_TO_HIDE_NAVIGATION.some(path => pathName.startsWith(path));
  }, [location]);

  useEffect(() => {
    if (showRegionSelector) {
      regionAccessor.saveRegion(regionAccessor.getRegion());
    }
  }, [regionAccessor, showRegionSelector, pathname]);

  const content = (
    <Suspense fallback={<Spinner size="large" />}>
      <Switch>
        <PrivateRoute
          {...commonPrivateRouteProps}
          path={pagePaths.CDM_BASE_PATH}
          resourceName={VibeDomainResources.MDM}
        >
          <MDMApp />
        </PrivateRoute>

        <PrivateRoute
          {...commonPrivateRouteProps}
          path={pagePaths.RTWO_BASE_PATH}
          resourceName={VibeDomainResources.RTWO}
        >
          <RtwoApp regionAccessor={regionAccessor} i18n={i18n} />
        </PrivateRoute>

        <PrivateRoute
          {...commonPrivateRouteProps}
          path={pagePaths.FORECASTING_BASE_PATH}
          resourceName={VibeDomainResources.FORECASTING}
        >
          <ForecastingApp />
        </PrivateRoute>

        <PrivateRoute
          {...commonPrivateRouteProps}
          path={pagePaths.GEN_AI_BASE_PATH}
          resourceName={VibeDomainResources.GEN_AI}
        >
          <GenAIApp />
        </PrivateRoute>

        <PrivateRoute
          {...commonPrivateRouteProps}
          path={pagePaths.PLANNING_BASE_PATH}
          resourceName={VibeDomainResources.PLANNING}
        >
          <PlanningApp />
        </PrivateRoute>

        <PrivateRoute
          {...commonPrivateRouteProps}
          path={pagePaths.SCHEDULING_BASE_PATH}
          resourceName={VibeDomainResources.SCHEDULING}
        >
          <ScheduleManagementApp user={user} i18n={i18n} />
        </PrivateRoute>
        <PrivateRoute
          {...commonPrivateRouteProps}
          path={pagePaths.INPUTS_CENTRAL_BASE_PATH}
          resourceName={VibeDomainResources.INPUTS_CENTRAL}
        >
          <InputsCentral />
        </PrivateRoute>

        <PrivateRoute
          {...commonPrivateRouteProps}
          path={pagePaths.REPORT_BASE_PATH}
          resourceName={VibeDomainResources.REPORTS_AND_ANALYTICS}
          isGICall={true}
        >
          <ReportsApp user={user} />
        </PrivateRoute>

        <PrivateRoute
          {...commonPrivateRouteProps}
          path={pagePaths.EXCEL_ADD_IN}
          resourceName={EXCEL_ADD_IN_DOMAIN_RESOURCE}
          isGICall={true}
        >
          <ExcelAddIn />
        </PrivateRoute>

        <PrivateRoute
          {...commonPrivateRouteProps}
          path={pagePaths.CONFIGURATION_BASE_PATH}
          resourceName={VibeDomainResources.CONFIGURATION}
        >
          <ConfigurationsApp user={user} i18n={i18n} />
        </PrivateRoute>

        <PrivateRoute
          {...commonPrivateRouteProps}
          path={pagePaths.NOTIFICATION_CENTER_BASE_PATH}
          resourceName={VibeDomainResources.NOTIFICATION_CENTER}
          isGICall={true}
        >
          <NotificationCenterApp i18n={i18n} />
        </PrivateRoute>

        <Route exact path={pagePaths.ACCESS_DENIED_PAGE}>
          <AccessDenied />
        </Route>

        <Route exact path={pagePaths.ERROR_PAGE}>
          <ErrorPage />
        </Route>

        <Route exact path={pagePaths.HOME_PAGE}>
          <Home />
        </Route>

        <Redirect to={pagePaths.HOME_PAGE} />
      </Switch>
    </Suspense>
  );

  const navigationMenuLabels: AppLayoutProps.Labels = {
    navigationClose: 'close main navigation',
    navigation: 'navigation bar',
    navigationToggle: 'open main navigation'
  };

  return (
    <div>
      <StyledHeader id="header">
        <Header
          showOptions={true}
          showRegionSelector={pathname.includes(pagePaths.REALTIME_SKILL_VIEW_PAGE) ? false : showRegionSelector}
          regionAccessor={regionAccessor}
          onLogout={logout}
          showCnRegion={showCnRegion}
        />
      </StyledHeader>

      <AppLayout
        {...toolsProps}
        {...splitPanelProps}
        content={content}
        navigation={<Navigation />}
        notifications={<Notifications />}
        headerSelector="#header"
        footerSelector="#footer"
        navigationOpen={
          // TODO: https://issues.amazon.com/issues/Optimus-7553
          navigationOpen
        }
        onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
        ariaLabels={navigationMenuLabels}
        navigationHide={isNavigationHidden}
      />

      <Footer />
    </div>
  );
};

export default AuthenticatedApp;
